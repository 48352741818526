.logo {
    width: 20px;
    height: auto;
}

.brand_user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 10px 3px 10px;
}

.login {
    text-align: right;
}

